'use client'

import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons'
import { CloseButton, Flex } from '@chakra-ui/react'
import { showDialog } from 'components/common/Dialog'
import { NCSendGTMEvent } from 'components/common/nextJs/components/nextCompatibleGoogleTags'
import { NCImage } from 'components/common/nextJs/components/nextCompatibleImage'
import { cn } from 'components/common/utils/tailwindUtils'
import { FirebaseGroupDetails, FirebaseGroupPlayerProfileEntry } from 'data/common'
import React, {
  CSSProperties,
  Component,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import { ShortcutIcon } from '../components/ShortcutIcon'
import { EditOnInteraction, EditableStringDiv } from '../components/common/EditableStringDiv'
import { Player } from '../data/Player'
import plusButtonIcon from '../icons/plus_button.png'

type DisplayStyle = 'icon-only' | 'name+icon' | 'expanding'
export type SelectionState = 'selected' | 'deselected' | 'normal'

type CommonPlayerTileProps = {
  player: Player
  size: number
  playerNameFontSize?: number
  shortcut?: string
  extra?: (player: Player) => ReactNode
  onPlayerClick?: (player: Player) => void
  onPlayerDblClick?: (player: Player) => void
  expandedWidth?: string
  onPlayerNameUpdate?: (player: Player, nameChange: string, approve?: boolean) => void
  onPlayerSubmit?: (player: Player) => void
  onPlayerDelete?: (player: Player) => void
  onFocus?: () => void
  onBlur?: () => void
  onRequestEditMode?: () => void
  selectionState?: SelectionState
  editMode: boolean
  canEnableEditing: boolean
  focusOnMountIfExpanded?: boolean
  hideRequestChangeActions?: boolean | undefined
  groupPlayerNames?: string[] | undefined
}

type EditablePlayerTileProps = CommonPlayerTileProps & {
  onPlayerNameUpdate: (
    player: Player & (Player | Pick<Player, 'id' | 'index' | 'name'>),
    nameChange: string,
    approve?: boolean,
  ) => void
  onPlayerDelete?: (player: Player) => void
  onFocus?: () => void
  onBlur?: () => void
} & (
    | {
        displayStyle?: 'name+icon'
        expandedWidth: string
      }
    | {
        displayStyle: 'expanding'
        expandedWidth: string
      }
  )

type NonEditablePlayerTileProps = CommonPlayerTileProps & {
  displayStyle: 'icon-only'
}

type PlayerTileProps = EditablePlayerTileProps | NonEditablePlayerTileProps

export type PlayerTileHandle = {
  getIconRect: () => DOMRect | undefined
}

export const PlayerTile = React.forwardRef<PlayerTileHandle, PlayerTileProps>(function PlayerTile(
  {
    player,
    size,
    playerNameFontSize,
    shortcut,
    extra,
    onPlayerClick,
    onPlayerDblClick,
    expandedWidth,
    onPlayerNameUpdate,
    onPlayerSubmit,
    onPlayerDelete,
    onFocus,
    onBlur,
    onRequestEditMode,
    selectionState: initialSelectionState = 'normal',
    editMode,
    canEnableEditing,
    focusOnMountIfExpanded,
    hideRequestChangeActions,
    groupPlayerNames,
    displayStyle = 'name+icon',
  },
  ref,
) {
  const [state, setState] = React.useState({
    expanded: displayStyle === 'name+icon',
    initialName: player.name,
  })
  const editableRef = React.useRef<EditableStringDiv>(null)
  const iconRef = React.useRef<HTMLDivElement>(null)

  useImperativeHandle(
    ref,
    () => ({
      getIconRect: () => iconRef.current?.getBoundingClientRect(),
    }),
    [],
  )
  useEffect(() => {
    console.log('focusOnMountIfExpanded', { focusOnMountIfExpanded, player })
  }, [focusOnMountIfExpanded, player])
  const getMode = React.useCallback(() => {
    return displayStyle || 'name+icon'
  }, [displayStyle])

  const handleOnIconClick = React.useCallback(
    (e: React.MouseEvent) => {
      const mode = getMode()
      e.stopPropagation()
      onPlayerClick?.(player)
      setState((prevState) => ({
        ...prevState,
        expanded: mode === 'expanding' && !prevState.expanded,
      }))
    },
    [getMode, onPlayerClick, player],
  )

  const handleOnIconDblClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      onPlayerDblClick?.(player)
    },
    [onPlayerDblClick, player],
  )

  const handleNameChange = React.useCallback(
    (name: string, approve?: boolean) => {
      if (groupPlayerNames !== undefined) return
      onPlayerNameUpdate?.(player, name.trim(), approve)
    },
    [groupPlayerNames, onPlayerNameUpdate, player],
  )

  const handleNameFocus = React.useCallback(() => {
    onFocus?.()
  }, [onFocus])

  const handleNameBlur = React.useCallback(() => {
    const name = editableRef.current?.value()
    if (!state.initialName.length && name?.length) {
      NCSendGTMEvent({
        event: 'player_submitted',
        category: 'player',
        name,
      })
    } else if (state.initialName !== name) {
      NCSendGTMEvent({
        event: 'player_update',
        category: 'player',
      })
    }
    onBlur?.()
  }, [state.initialName, onBlur])

  const handleRemoveClicked = React.useCallback(
    async (
      onPlayerDelete: (player: Player) => void,
      onPlayerNameUpdate: (player: Player, name: string) => void,
    ) => {
      return await showDialog({
        title: 'Do you want remove this player?',
        children: () => (
          <>
            This action cannot be undone. You are removing{' '}
            <span style={{ color: player.color }}>{player.name}</span>
          </>
        ),
        positiveButtonProps: {
          text: 'Yes',
          onClicked: () => {
            onPlayerDelete(player)
            return true
          },
        },
        negativeButtonProps: {
          text: 'Cancel',
          onClicked: () => {
            onPlayerNameUpdate(player, player.name)
            return true
          },
        },
        user_dismissable: false,
      })
    },
    [player],
  )

  const handleSubmit = React.useCallback(
    async (value: string) => {
      const trimmedValue = value.replace(/\s+/g, ' ').trim()
      if (!trimmedValue) {
        return !!(
          onPlayerDelete &&
          onPlayerNameUpdate &&
          (await handleRemoveClicked(onPlayerDelete, onPlayerNameUpdate))
        )
      } else {
         onPlayerNameUpdate?.(player, trimmedValue)
        if (onPlayerSubmit) {
          onPlayerSubmit({
            ...player,
            name: trimmedValue,
          })
          return true
        } else return false
      }
    },
    [handleRemoveClicked, onPlayerDelete, onPlayerNameUpdate, onPlayerSubmit, player],
  )

  const currentSelectionState: SelectionState = initialSelectionState || 'normal'
  const mode: DisplayStyle = getMode()
  const ExtraChild = extra?.(player)
  const isExpanded = mode === 'name+icon' || (mode === 'expanding' && state.expanded)
  let containerWidth: string | undefined
  switch (mode) {
    case 'icon-only':
      containerWidth = `${size}px`
      break
    case 'name+icon':
      containerWidth = expandedWidth
      break
    case 'expanding':
      if (state.expanded) containerWidth = expandedWidth
      else containerWidth = `${size}px`
      break
  }

  return (
    <PlayerTileContainer
      className={cn('group relative')}
      selectionState={currentSelectionState}
      style={{
        height: `${size}px`,
      }}>
      {isExpanded && (
        <Flex
          direction={'column'}
          gap={4}
          className='box-border flex h-full w-full flex-wrap content-center justify-center rounded-full
            bg-[hsla(191,39%,25%,80%)] text-center align-middle font-league-spartan text-white'
          style={{
            paddingLeft: (size * 5) / 4,
            paddingRight: size / 2,
            maxWidth: expandedWidth,
          }}
          translate={'no'}>
          <Flex gap={8}>
            <EditablePlayerDiv
              player={player}
              editableRef={editableRef}
              disabled={!editMode}
              editOn='dblclick'
              placeholder={'Enter name'}
              focusOnMount={focusOnMountIfExpanded}
              hideRequestChangeActions={hideRequestChangeActions}
              handleRequestNameChange={handleNameChange}
              onDoubleClick={() => {
                if (canEnableEditing) {
                  onRequestEditMode?.()
                }
              }}
              onFocus={handleNameFocus}
              // onChange={handleNameChange}
              onSubmit={handleSubmit}
              onBlur={handleNameBlur}
              style={{
                fontSize: playerNameFontSize ?? size * 0.6,
              }}
              requestedChangesStyle={{
                fontSize: (playerNameFontSize ?? size * 0.6) * 0.8,
              }}
              groupPlayerNames={groupPlayerNames}
            />
          </Flex>
        </Flex>
      )}
      {ExtraChild && (
        <div
          className={'transition-all duration-200'}
          translate={'no'}>
          {ExtraChild}
        </div>
      )}
      <PlayerTileIcon
        className={cn('left-0', isExpanded && 'absolute')}
        ref={iconRef}
        selectionState={currentSelectionState}
        backgroundColor={player.color}
        size={size}
        draggable={true}
        onClick={handleOnIconClick}
        onDoubleClick={handleOnIconDblClick}>
        <span translate={'no'}>{getPlayerInitials(player)}</span>
      </PlayerTileIcon>
      {shortcut && (
        <ShortcutIcon
          className='absolute left-0 top-[-8px]'
          shortcut={shortcut}
          fontSize={`${size / 3}px`}
        />
      )}
      {player.source === 'stat_record' && displayStyle !== 'icon-only' && (
        <Flex
          className='pointer-events-none absolute bottom-0 left-0 h-full w-full items-end justify-start text-xs'
          style={{
            fontVariationSettings: "'wght' 600",
            transform: 'translateY(30%)',
          }}>
          👁️‍🗨️
        </Flex>
      )}
      {editMode && onPlayerDelete && (
        <button
          className='hidden h-6 w-6 cursor-pointer items-center justify-center rounded-full border-none p-2
            group-focus-within:flex group-hover:flex hover:brightness-75'
          aria-label=''
          onClick={
            onPlayerNameUpdate &&
            onPlayerDelete &&
            (() => handleRemoveClicked(onPlayerDelete, onPlayerNameUpdate))
          }>
          <DeleteIcon
            color={'black'}
            className='h-full w-full'
          />
        </button>
      )}
    </PlayerTileContainer>
  )
})

type EditablePlayerDiv = {
  player: Player
  editableRef: React.RefObject<EditableStringDiv>
  disabled?: boolean | undefined
  editOn?: EditOnInteraction | undefined
  focusOnMount?: boolean | undefined
  blurOnEnter?: boolean | undefined
  placeholder?: string | undefined
  translate?: 'no' | 'yes' | undefined
  hideRequestChangeActions?: boolean | undefined
  handleRequestNameChange?: (name: string, approve?: boolean) => void
  onDoubleClick?: () => void
  onFocus?: React.FocusEventHandler<HTMLDivElement> | undefined
  onChange?: ((changedValue: string) => void) | undefined
  onSubmit?:
    | ((changedValue: string) => Promise<boolean>)
    | ((changedValue: string) => boolean)
    | ((changedValue: string) => void)
    | undefined
  onBlur?: ((value: string, event?: React.FocusEvent) => void) | undefined
  style?: React.CSSProperties | undefined
  requestedChangesStyle?: React.CSSProperties | undefined
  className?: string | undefined
  groupPlayerNames?: string[] | undefined
}

export function EditablePlayerDiv({
  player,
  editableRef,
  disabled,
  editOn,
  focusOnMount,
  blurOnEnter = true,
  placeholder,
  translate = 'no',
  hideRequestChangeActions,
  handleRequestNameChange,
  onDoubleClick,
  onFocus,
  onChange,
  onSubmit,
  onBlur,
  style,
  requestedChangesStyle,
  className,
  groupPlayerNames,
}: EditablePlayerDiv) {
  return (
    <>
      {player.requestedChanges?.name.changes ?
        <div
          translate={'no'}
          className='text-[#FF4136] line-through'
          style={requestedChangesStyle}>
          {player.name.trim()}
        </div>
      : undefined}
      <EditableStringDiv
        ref={editableRef}
        className={cn('font-leagueSpartan whitespace-nowrap', className)}
        style={style}
        onDoubleClick={onDoubleClick}
        disabled={disabled}
        editOn={editOn}
        onFocus={onFocus}
        onChange={onChange}
        focusOnMount={focusOnMount}
        onSubmit={onSubmit}
        blurOnEnter={blurOnEnter}
        onBlur={onBlur}
        placeholder={placeholder}
        translate={translate}
        suggestions={groupPlayerNames}>
        {player.requestedChanges?.name.changes ?? player.name.trim()}
      </EditableStringDiv>
      {player.requestedChanges?.name.changes &&
        player.source === 'local' &&
        !hideRequestChangeActions && (
          <>
            <CheckCircleIcon
              className='hover:text-[#2ECC40]'
              onClick={(e) => {
                e.stopPropagation()
                player.requestedChanges?.name.changes &&
                  handleRequestNameChange &&
                  handleRequestNameChange(player.requestedChanges.name.changes, true)
              }}
            />
            <CloseButton
              className='hover:text-[#2ECC40]'
              onClick={(e) => {
                e.stopPropagation()
                player.requestedChanges?.name.changes &&
                  handleRequestNameChange &&
                  handleRequestNameChange(player.requestedChanges.name.changes, false)
              }}
            />
          </>
        )}
    </>
  )
}

export interface AddPlayerTileProps {
  size: number
  onSubmit?: (name: string) => void
  onClick: () => void
  displayStyle?: 'expanded' | 'circle'
  shortcut?: string
}

export class AddPlayerTile extends Component<AddPlayerTileProps> {
  render() {
    const displayStyle = this.props.displayStyle || 'expanded'
    return (
      <div
        className='] relative flex shrink-0 cursor-pointer flex-row items-center justify-center border-4 border-solid
          border-white/25 bg-[rgba(16,15,13,0.50)] font-league-spartan hover:bg-[#282c34]'
        style={{
          border: `${this.props.size / 15}px solid rgba(255, 255, 255, 0.26)`,
          borderRadius: this.props.size / 2,
          height: `${this.props.size}px`,
          minWidth: `${this.props.size}px`,
        }}
        onClick={this.props.onClick}>
        <NCImage
          draggable={false}
          src={plusButtonIcon}
          style={{ width: this.props.size / 2, height: this.props.size / 2 }}
          alt={'assign a player to this event'}
          className='cursor-pointer'
        />
        {this.props.shortcut && (
          <ShortcutIcon
            className='absolute left-0 top-[-8px]'
            shortcut={this.props.shortcut}
            fontSize={`${this.props.size / 3}px`}
          />
        )}
      </div>
    )
  }
}

export function getInitials(words: string[]): string[] {
  // initials except including full multi digit when a word is just numbers
  return words.map((word) => {
    if (word.match(/^\d+$/)) {
      return word
    }
    return word[0]
  })
}

export function getPlayerInitials(player: Player | FirebaseGroupDetails): string {
  const splitName = player.name.split(/\s+/).filter((it) => it.replace(/[^a-zA-Z0-9]/g, ''))
  const initials = getInitials(splitName)

  const limitedInitals: string[] = []

  let initialsLength = 0
  for (const initial of initials) {
    if (initialsLength + initial.length <= 3 || initials.firstOrNull() === initial) {
      limitedInitals.push(initial)
      initialsLength += initial.length
    } else {
      break
    }
  }

  return limitedInitals.join('')
}

export function isNumeric(str: string | undefined) {
  return str && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function PlayerTileContainer(
  props: PropsWithChildren<{
    style?: CSSProperties
    className?: string
    selectionState?: SelectionState
  }>,
) {
  return (
    <div
      className={cn(
        'relative flex flex-row items-center justify-center font-league-spartan hover:opacity-100',
        props.selectionState === 'deselected' && 'opacity-50',
        props.selectionState === 'selected' && 'opacity-100',
        props.className,
      )}
      style={props.style}>
      {props.children}
    </div>
  )
}
const PlayerTileIcon = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    style?: CSSProperties
    className?: string
    selectionState?: SelectionState
    backgroundColor: string
    size: number
  }> &
    HTMLProps<HTMLDivElement>
>(function PlayerTileIcon({ size, selectionState, backgroundColor, ...props }, ref) {
  return (
    <div
      {...props}
      style={{ width: size, height: size, fontSize: size * 0.6, ...props.style }}
      className={cn(
        'pointer-events-auto relative box-border rounded-full p-[2px]',
        props.className,
      )}>
      <div
        ref={ref}
        className={cn(
          `relative flex aspect-square h-full shrink-0 cursor-grab select-none items-center justify-center
          rounded-full uppercase text-white transition-all duration-200 font-semibold group-hover:scale-[120%]`,
          `[&_span]:bg-inherit [&_span]:bg-clip-text [&_span]:text-transparent [&_span]:brightness-[2]
          [&_span]:contrast-[30] [&_span]:grayscale-[1] [&_span]:invert
          [&_span]:[filter:var(--tw-invert)_var(--tw-brightness)_var(--tw-grayscale)_var(--tw-contrast)]`,
          selectionState === 'selected' && 'outline outline-offset-2',
        )}
        style={{ backgroundColor: backgroundColor }}>
        {props.children}
      </div>
    </div>
  )
})
