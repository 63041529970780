import { isArray } from '@chakra-ui/utils'
import { ANY, DEFINED, Definitions } from 'templates/Definitions'
import {
  ButtonConfig,
  EventDefinition,
  QwertyKeys,
  StatCategory,
  createButtonConfig,
  mergeArrayMaps,
  resolveStringOrFn,
} from 'templates/TemplateConfig'
import { Colors } from '../Colors'
import { IconInfo } from '../components/common/BackgroundIcon'
import { LayeredIcon } from '../components/common/LayeredIconView'
import { PartialBy } from '../components/common/utils/typescriptUtils'
import '../components/common/utils/typescriptUtils'

function getBaseDimensions<DK extends string, T extends EventDimension<any, any>>(
  baseDefinition: BaseDefinition<DK, T>,
) {
  return getDimensions<T>(
    baseDefinition.dimensions as unknown as Partial<EventDimensionsDefinition<T>>,
  )
}

function getDimensions<T extends EventDimension<any, any>>(
  dimensions: Partial<EventDimensionsDefinition<T>>,
): Map<T[0], T[1][]> {
  const results = Object.entries(dimensions).map(([dimension, variants]) => {
    const castedVariantKeys = Object.keys(
      (variants as OptionsByVariant<T[1]> | undefined) ?? {},
    ).map((it) => it as keyof OptionsByVariant<T[1]>)
    return [dimension as T[0], castedVariantKeys] as const
  })

  return new Map<T[0], T[1][]>(results)
}

function generateVariantDescriptors<
  DK extends string,
  T extends EventDimension<any, any>,
  D extends T[0],
>(
  baseDefinition: BaseDefinition<DK, T>,
  newDimensions?: Partial<EventDimensionsDefinition<EventDimension<D, any>>>,
): VariantKeyByDimension<EventDimension<T[0], T[1] | string>>[] {
  let results: Partial<VariantKeyByDimension<EventDimension<T[0], T[1] | string>>>[] = [{}]
  let calculatedDimensions: Map<T[0], T[1][]>
  if (newDimensions) {
    calculatedDimensions = mergeArrayMaps(
      getBaseDimensions<DK, T>(baseDefinition),
      getDimensions(newDimensions),
    )
  } else {
    calculatedDimensions = getBaseDimensions<DK, T>(baseDefinition)
  }

  calculatedDimensions
    .entriesArray()
    .orderBy(([dim]) => baseDefinition.keyOrder[dim])
    .map(([dimension, variantKeys]) => {
      results = results
        .map(
          (existing) =>
            ({
              ...existing,
              [dimension]: null,
            }) satisfies VariantKeyByDimension<T>,
        )
        .concat(
          results.flatMap((existing) =>
            variantKeys.map(
              (variantKey) =>
                ({
                  ...existing,
                  [dimension]: variantKey,
                }) satisfies VariantKeyByDimension<T>,
            ),
          ),
        )
    })
  return results as unknown as VariantKeyByDimension<EventDimension<T[0], T[1] | string>>[]
}

type SingleDimensionVariantData<VARIANTKEY extends string> = {
  dimension: string
  variantKey: string | undefined
  variant: VariantOptions<VARIANTKEY> | undefined
}
type VariantData<VARIANTKEY extends string> = SingleDimensionVariantData<VARIANTKEY>[]

function getSingleDimensionVariantData<
  DK extends string,
  T extends EventDimension<D, VK>,
  D extends string,
  VK extends string,
>(
  baseDefinition: BaseDefinition<DK, T>,
  dimension: D,
  variantKey: VK | undefined,
): SingleDimensionVariantData<VK> {
  const dimensionsVariants = baseDefinition.dimensions[dimension as D] as Record<
    VK,
    VariantOptions<VK>
  >
  const variant: VariantOptions<VK> | undefined =
    variantKey ? dimensionsVariants[variantKey] : undefined
  return {
    dimension,
    variantKey: variantKey,
    variant,
  }
}

function getVariantData<
  DK extends string,
  T extends EventDimension<D, VK>,
  D extends string,
  VK extends string,
>(baseDefinition: BaseDefinition<DK, T>, descriptor: VariantKeyByDimension<T>): VariantData<VK> {
  return Object.entries(descriptor).map(([dimension, variantKey]) => {
    const castedDimension = dimension as D
    const castedVariantkey = variantKey as VK | undefined
    return getSingleDimensionVariantData(baseDefinition, castedDimension, castedVariantkey)
  })
}

function generateEventVariants<
  DK extends string,
  T extends EventDimension<D, VK>,
  D extends string,
  VK extends string,
>(
  baseDefinition: BaseDefinition<DK, T>,
  newDimensions?: Partial<EventDimensionsDefinition<EventDimension<D, string>>>,
): {
  variantData: VariantData<VK | string>
  variantDescriptor: VariantKeyByDimension<EventDimension<D, VK | string>>
}[] {
  const results = generateVariantDescriptors(baseDefinition, newDimensions)
  return results.map((it) => {
    const variantData = getVariantData<DK, T, D, VK>(baseDefinition, it)
    return { variantData, variantDescriptor: it }
  })
}

export function generateEventDefinitions<
  DEF_KEY extends string,
  T extends EventDimension<string, string>,
>({
  baseDefinition,
  newDimensions,
  opposing,
}: {
  baseDefinition: BaseDefinition<DEF_KEY, T>
  newDimensions?: Partial<EventDimensionsDefinition<EventDimension<T[0], string>>>
  opposing?: (
    base: BaseDefinition<DEF_KEY, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => EventDefinition | undefined | EventDefinition[]
}): TypedEventDefinition<DEF_KEY, T>[] {
  const dimensions = mergeDimensions(baseDefinition.dimensions, newDimensions)

  return generateEventVariants(baseDefinition, newDimensions).map(
    ({ variantData, variantDescriptor }) => {
      const dimensionsVariant = Object.entries(dimensions).reduce<Partial<OptionsByDimension<T>>>(
        (previousValue, [dimension, optionsByvariant]) => {
          const castedDimension = dimension as keyof EventDimensionsDefinition<T>
          const castedVariant = variantDescriptor[
            castedDimension
          ] as keyof EventDimensionsDefinition<T>[T[0]]
          previousValue[castedDimension] = (castedVariant &&
            dimensions[castedDimension][castedVariant]) as OptionsByDimension<T>[T[0]]
          return previousValue
        },
        {},
      ) as unknown as OptionsByDimension<T>

      return {
        key: getVariantDefinitionKey(baseDefinition, variantData),
        icon: baseDefinition?.icon?.(baseDefinition, dimensionsVariant, variantDescriptor),
        title:
          baseDefinition?.titleBuilder?.(baseDefinition, dimensionsVariant, variantDescriptor) ??
          baseDefinition.title,
        fallbackKey: getFallbackVariantDefinitionKey(baseDefinition, newDimensions, variantData),
        color:
          baseDefinition?.color?.(baseDefinition, dimensionsVariant, variantDescriptor) ??
          Colors.color_grey,
        description: () =>
          `${[baseDefinition?.description]
            .concat(variantData.mapNotNull((it) => it.variant?.description))
            .filterNotNull()
            .map(resolveStringOrFn)
            .join(' | ')}`,
        type: 'TypedEventDefinition',
        dataTags: (baseDefinition.dataTags ?? []).concat(
          variantData.flatMap((it) => it.variant?.dataTags ?? []),
        ),
        descriptor: variantDescriptor,
        getOpposing:
          opposing &&
          ((): EventDefinition[] => {
            const result = opposing(baseDefinition, dimensionsVariant, variantDescriptor)
            if (!result) return []
            if (isArray(result)) return result
            return [result]
          }),
        disableChoosing: baseDefinition.disableChoosingFor?.(
          baseDefinition,
          dimensionsVariant,
          variantDescriptor,
        ),
      } satisfies TypedEventDefinition<DEF_KEY, T>
    },
  )
}

export function generateHeirarchy<
  DK extends string,
  T extends EventDimension<any, any>,
  D2 extends T[0],
>({
  baseDefinition,
  eventDefinitions,
  newDimensions,
}: {
  baseDefinition: BaseDefinition<DK, T>
  eventDefinitions: EventDefinition[]
  newDimensions?: Partial<EventDimensionsDefinition<EventDimension<D2, string>>>
}): Map<StatCategory, (StatCategory | EventDefinition)[]> {
  const eventDefinitionsMap = eventDefinitions.reduce<Record<string, EventDefinition>>(
    (previousValue, currentValue) => {
      previousValue[currentValue.key] = currentValue
      return previousValue
    },
    {},
  )
  const results = new Map<StatCategory, (StatCategory | EventDefinition)[]>()
  const dimensions = getBaseDimensions(baseDefinition)
  const descriptors = generateVariantDescriptors(baseDefinition, newDimensions)
  const dimensionCountDescriptorMap = descriptors.reduce<
    Record<number, VariantKeyByDimension<T>[]>
  >((p, descriptor) => {
    const dimensionsCount = Object.values(descriptor).filter((variantKey) => {
      const castedVariantKey = variantKey as string | undefined
      return !!variantKey
    }).length
    if (!p[dimensionsCount]) {
      p[dimensionsCount] = [descriptor]
    } else {
      p[dimensionsCount].push(descriptor)
    }
    return p
  }, {})

  for (let dimensionCount = 1; dimensionCount <= dimensions.size; dimensionCount++) {
    if (dimensionCountDescriptorMap[dimensionCount]?.length) {
      const potentialChildren = dimensionCountDescriptorMap[dimensionCount].map((descriptor) => {
        return {
          descriptor,
          variantData: getVariantData(baseDefinition, descriptor),
        }
      })

      // children based on null dimensions
      dimensionCountDescriptorMap[dimensionCount - 1].forEach((parentDescriptor) => {
        const parentVariantData = getVariantData(baseDefinition, parentDescriptor)
        const parentKey = getVariantDefinitionKey(baseDefinition, parentVariantData)
        const parent = eventDefinitionsMap[parentKey]
        const childResults = potentialChildren.filter(
          ({ descriptor: childDescriptor, variantData: childVariantData }) => {
            // all parent's dimension are included in the child
            return (
              parentVariantData.length === 0 ||
              parentVariantData.all((parent) => {
                return childVariantData.any((child) => {
                  return (
                    child.dimension === parent.dimension &&
                    (!parent.variantKey || child.variantKey === parent.variantKey)
                  )
                })
              })
            )
          },
        )
        const childKeys = childResults.map((it) =>
          getVariantDefinitionKey(baseDefinition, it.variantData),
        )
        const children = childKeys.map((key) => eventDefinitionsMap[key])
        if (!children.length) {
          return
        }
        if (results.has(parent)) {
          results.get(parent)?.push(...children)
        } else {
          results.set(parent, children)
        }
      })

      // children based on childVariant within a DimensionVariant
      dimensionCountDescriptorMap[dimensionCount].forEach((parentDescriptor) => {
        const parentVariantData = getVariantData(baseDefinition, parentDescriptor)
        const parentKey = getVariantDefinitionKey(baseDefinition, parentVariantData)
        const parent = eventDefinitionsMap[parentKey]
        const parentVariantDataWithChildren = parentVariantData.filter(
          (it) => !!it.variant?.childVariant,
        )

        const childResults = parentVariantDataWithChildren
          .map((variantDataToReplace) => {
            const childDescriptor = {
              ...parentDescriptor,
              [variantDataToReplace.dimension]: variantDataToReplace.variant?.childVariant as T[0],
            } satisfies VariantKeyByDimension<T>
            return childDescriptor as VariantKeyByDimension<T>
          })
          .map((descriptor) => getVariantData(baseDefinition, descriptor))

        const childKeys = childResults.map((variantData) =>
          getVariantDefinitionKey(baseDefinition, variantData),
        )
        const children = childKeys.map((key) => eventDefinitionsMap[key]).filterNotNull()
        if (!children.length) {
          return
        }
        if (results.has(parent)) {
          results.get(parent)?.push(...children)
        } else {
          results.set(parent, children)
        }
      })
    }
  }
  return results
}

export type BaseDefinition<KEY extends string, T extends EventDimension<string, string>> = {
  baseKey: KEY
  title: string | (() => string)
  dimensions: EventDimensionsDefinition<T>
  keyOrder: DimensionPriority<T[0]>
  iconOrder: DimensionPriority<T[0]>
  colorOrder: DimensionPriority<T[0]>
  dataTags?: string[] | undefined
  color?: (
    base: BaseDefinition<KEY, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ) => string
  titleBuilder?: (
    base: BaseDefinition<KEY, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ) => string | (() => string)
  description?: string | (() => string)
  icon?: (
    base: BaseDefinition<KEY, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ) => LayeredIcon
  baseIcon?: IconInfo
  baseColor?: string
  /** EventDefinition will still be generated but it wont be selectable in the button bar*/
  disableChoosingFor?: (
    base: BaseDefinition<KEY, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => boolean
}
export type EventDimensionsDefinition<T extends EventDimension<string, string>> = {
  [Dim in T as Dim[0]]: OptionsByVariant<Dim[1]>
}
export type DimensionPriority<Dim extends string> = {
  [T in Dim as T]: number
}
export type EventDimension<DIMENSIONKEY extends string, VARIANTKEY extends string> = [
  DIMENSIONKEY,
  VARIANTKEY,
]
export type VariantKeyByDimension<DIMENSION extends EventDimension<string, string>> = {
  [dim in DIMENSION as dim[0]]: dim[1] | null
}
export type VariantKeyByDimensionFilter<DIMENSION extends EventDimension<string, string>> = {
  [dim in DIMENSION as dim[0]]?: dim[1] | null | typeof ANY | typeof DEFINED
}

export type EventDimensionFromTypedDefinition<
  T extends TypedEventDefinition<string, EventDimension<string, string>>,
> = T extends TypedEventDefinition<string, infer U> ? U : never

export type OptionsByDimension<DIMENSION extends EventDimension<string, string>> = {
  [dim in DIMENSION as dim[0]]: VariantOptions<DIMENSION[1]> | null
}
export type OptionsByVariant<VARIANTKEY extends string> = {
  [key in VARIANTKEY as VARIANTKEY]: VariantOptions<VARIANTKEY>
}
export type VariantOptions<VARIANTKEY extends string> = {
  childVariant?: VARIANTKEY
  modifierIcon?: IconInfo | IconInfo[]
  modifierColor?: string
  title: string | (() => string)
  description?: string | (() => string)
  dataTags?: string[]
}

export type TypedEventDefinition<
  DK extends string,
  T extends EventDimension<string, string>,
> = Omit<EventDefinition, 'key' | 'type'> & {
  key: DK
  descriptor: VariantKeyByDimension<T>
  type: 'TypedEventDefinition'
  disableChoosing?: boolean
}

function getVariantDefinitionKey<
  DK extends string,
  T extends EventDimension<D, VK>,
  D extends string,
  VK extends string,
>(baseDefinition: BaseDefinition<DK, T>, variantData: VariantData<VK>) {
  return `${[baseDefinition.baseKey as string]
    .concat(variantData.mapNotNull((it) => it.variantKey))
    .filterNotNull()
    .join('_')}` as DK
}
function getFallbackVariantDefinitionKey<
  DK extends string,
  T extends EventDimension<D, VK>,
  D extends string,
  VK extends string,
>(
  baseDefinition: BaseDefinition<DK, T>,
  newDimensions: Partial<EventDimensionsDefinition<EventDimension<D, string>>> | undefined,
  variantData: VariantData<VK>,
) {
  const fallbackKey = `${[baseDefinition.baseKey as string]
    .concat(
      variantData
        .filter((it) => {
          const customVariantKeys = Object.keys(newDimensions?.[it.dimension as D] ?? {})
          // filter out new dimensions
          return (
            !customVariantKeys.length ||
            customVariantKeys.none((variantKey) => variantKey === it.variantKey)
          )
        })
        .mapNotNull((it) => it.variantKey),
    )
    .filterNotNull()
    .join('_')}` as DK

  if (fallbackKey === getVariantDefinitionKey(baseDefinition, variantData)) return undefined

  return fallbackKey
}

export type CreateBaseDefinitionParams<
  K extends string,
  T extends EventDimension<string, string>,
  D extends string = T[0],
  VK extends string = T[1],
> = {
  baseKey: K
  title: string | (() => string)
  dimensions: EventDimensionsDefinition<T>
  keyOrder: DimensionPriority<D>
  iconOrder?: DimensionPriority<D>
  colorOrder?: DimensionPriority<D>
  dataTags?: string[] | undefined
  color?: (
    base: BaseDefinition<K, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => string
  description?: string | (() => string)
  icon?: (
    base: BaseDefinition<K, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => LayeredIcon
  titleBuilder?: (
    base: BaseDefinition<K, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => string | (() => string)
  baseIcon?: IconInfo
  baseColor?: string
  opposing?: (
    base: BaseDefinition<K, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => EventDefinition | undefined
  /** EventDefinition will still be generated but it wont be selectable in the button bar*/
  disableTrackFor?: (
    base: BaseDefinition<K, T>,
    optionsByDimension: OptionsByDimension<T>,
    variantKeyByDimension: VariantKeyByDimension<T>,
  ) => boolean
}
export const createBaseDefinition = <
  K extends string,
  T extends EventDimension<D, VK>,
  D extends string = T[0],
  VK extends string = T[1],
>({
  baseColor,
  baseIcon,
  baseKey,
  color = BaseDefinition.DEFAULT_REPLACE_BASE_COLOR_STRATEGY,
  dataTags,
  description,
  dimensions,
  icon = BaseDefinition.DEFAULT_REPLACE_BASE_ICON_STRATEGY,
  titleBuilder = BaseDefinition.DEFAULT_REPLACE_BASE_TITLE_STRATEGY,
  keyOrder,
  iconOrder = keyOrder,
  colorOrder = keyOrder,
  title,
  disableTrackFor,
}: CreateBaseDefinitionParams<K, T>): BaseDefinition<K, T> => {
  return {
    baseKey: baseKey,
    keyOrder,
    iconOrder,
    colorOrder,
    title: title,
    dataTags: dataTags,
    dimensions: dimensions,
    icon: icon,
    baseIcon: baseIcon,
    baseColor: baseColor,
    color: color,
    titleBuilder: titleBuilder,
    description: description,
    disableChoosingFor: disableTrackFor,
  } satisfies BaseDefinition<K, T>
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BaseDefinition {
  export const DEFAULT_REPLACE_BASE_ICON_STRATEGY = <
    K extends string,
    T extends EventDimension<string, string>,
  >(
    base: BaseDefinition<K, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ): LayeredIcon => {
    const iconLayers = (Object.entries(dimensionVariants) as [T[0], VariantOptions<T[1]> | null][])
      .map((it) => [...it, base.iconOrder[it[0]]] as const)
      .orderBy(([, , order]) => order)
      .flatMap(([dimension, it, order]) =>
        !it?.modifierIcon ?
          order === 0 ?
            [base.baseIcon]
          : []
        : Array.isArray(it.modifierIcon) ? it.modifierIcon
        : [it.modifierIcon],
      )
      .filterNotNull()

    if (iconLayers.length === 0) return [base.baseIcon].filterNotNull()
    return iconLayers
  }
  export const DEFAULT_REPLACE_BASE_TITLE_STRATEGY = <
    K extends string,
    T extends EventDimension<string, string>,
  >(
    base: BaseDefinition<K, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ): string | (() => string) => {
    const titleLayers = (Object.entries(dimensionVariants) as [T[0], VariantOptions<T[1]> | null][])
      .map((it) => [...it, base.keyOrder[it[0]]] as const)
      .orderBy(([, , order]) => order)
      .map(([dimension, it, order]) =>
        !it?.title ?
          order === 0 ?
            base.title
          : undefined
        : it.title,
      )
      .filterNotNull()

    if (titleLayers.length === 0)
      return () => [base.title].filterNotNull().map(resolveStringOrFn).join(' ')
    return () => titleLayers.map(resolveStringOrFn).join(' ')
  }
  export const ICON_STACK_STRATEGY = <K extends string, T extends EventDimension<string, string>>(
    base: BaseDefinition<K, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ): LayeredIcon => {
    const iconLayers = (
      Object.entries(dimensionVariants) as [T[0], VariantOptions<T[1]> | undefined][]
    )
      .orderBy(([dimension]) => base.iconOrder[dimension])
      .flatMap(([dimension, it], index) => it?.modifierIcon ?? [])
      .filterNotNull()

    if (iconLayers.length === 0) return [base.baseIcon].filterNotNull()
    return [base.baseIcon, ...iconLayers].filterNotNull()
  }
  export const DEFAULT_REPLACE_BASE_COLOR_STRATEGY = <
    K extends string,
    T extends EventDimension<string, string>,
  >(
    base: BaseDefinition<K, T>,
    dimensionVariants: OptionsByDimension<T>,
    variantDescriptor: VariantKeyByDimension<T>,
  ): string => {
    return (
      (Object.entries(dimensionVariants) as [T[0], VariantOptions<T[1]> | undefined][])
        .orderBy(([dimension]) => base.colorOrder[dimension])
        .map(([dim, it]) => it?.modifierColor)
        .filterNotNull()
        .lastOrNull() ??
      base.baseColor ??
      Colors.color_grey
    )
  }
}
declare global {
  interface Array<T> {
    concatUnion<T, L>(this: T[], other: L[]): (T | L)[]

    asButtonConfigs(
      this: (
        | EventDefinition
        | undefined
        | TypedEventDefinition<string, EventDimension<string, string>>
      )[],
      defs: Definitions<any>,
      mapper?: (config: ButtonConfig) => ButtonConfig,
    ): ButtonConfig[]
  }
}
// eslint-disable-next-line no-extend-native
Array.prototype.concatUnion = function <T, L>(this: T[], other: L[]): (T | L)[] {
  return [...this, ...other]
}

// eslint-disable-next-line no-extend-native
Array.prototype.asButtonConfigs = function (
  this: (
    | EventDefinition
    | undefined
    | TypedEventDefinition<string, EventDimension<string, string>>
  )[],
  defs: Definitions<any>,
  mapper?: (config: ButtonConfig) => ButtonConfig,
): ButtonConfig[] {
  return this.mapNotNull((it, index) => {
    if (!it) return undefined
    const config = createButtonConfig({
      definition: defs.get(it.key),
      shortcut: QwertyKeys.getOrNull(index),
      disableChoosing: it.disableChoosing,
    })
    return mapper?.(config) ?? config
  })
}

export function createTypedEventDefinition<DK extends string>(
  eventDefinition: PartialBy<Omit<EventDefinition, 'key'> & { key: DK }, 'type'>,
): TypedEventDefinition<DK, ['', '']> {
  return {
    ...eventDefinition,
    type: 'TypedEventDefinition',
    descriptor: { '': null },
  }
}

function mergeDimensions<T extends EventDimension<string, string>>(
  dimensions: EventDimensionsDefinition<T>,
  newDimensions?: Partial<EventDimensionsDefinition<EventDimension<T[0], string>>>,
): EventDimensionsDefinition<T> {
  if (!newDimensions) return { ...dimensions }
  return Object.keys(dimensions).reduce<Partial<EventDimensionsDefinition<T>>>(
    (previousValue, dim) => {
      const castedDim = dim as T[0]
      previousValue[castedDim] = {
        ...dimensions[castedDim],
        ...newDimensions[castedDim],
      }
      return previousValue
    },
    {},
  ) as unknown as EventDimensionsDefinition<T>
}
