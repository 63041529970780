'use client'

import { TourProvider } from '@reactour/tour'
import { allAchievements } from 'hooks/UseAchievementStore'
import { ReactNode } from 'react'

export default function TourProviderClient({ children }: { children: ReactNode }) {
  return (
    <TourProvider
      steps={Object.values(allAchievements)}
      showNavigation={false}
      showCloseButton={false}
      showBadge={false}
      scrollSmooth
      className='font-league-spartan'
      styles={{
        popover: (base) => ({
          ...base,
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }),
      }}>
      {children}
    </TourProvider>
  )
}
