'use client'

// import layouts
import { StoryBook } from 'NavigationBarEditor'
import { BodyScrollingContainer, FullPageContainer } from 'nextJs/layouts/appBaseWrappers'
import { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router'
import { PageLoader } from './components/PageLoader'

const About = lazy(() => import('./About'))
const BookAReview = lazy(() => import('./BookAReview'))
const MediaPage = lazy(() => import('./MediaPage'))
const App = lazy(() => import('./App'))
const DeleteAccountPage = lazy(() => import('./DeleteAccountPage'))
const Home = lazy(() => import('./Home'))
const StatRecordPage = lazy(() => import('./StatsRecordPage'))
const InsightsPage = lazy(() => import('./InsightsPage'))
const WatcherPage = lazy(() => import('./WatcherPage'))
const InternalSupportPage = lazy(() => import('./InternalSupportPage'))
const SignInPage = lazy(() => import('./SignInPage'))
const GroupRankingPage = lazy(() => import('./GroupRankingPage'))
const PublicLeaderboardPage = lazy(() => import('./PublicLeaderboardPage'))
const TeamStatsRegistrationPage = lazy(() => import('./TeamStatsRegistrationPage'))
const TeamInvitePage = lazy(() => import('./TeamInvitePage'))
const AdminPage = lazy(() => import('./AdminPage'))
const LeagueStatsDeckPage = lazy(() => import('./LeagueStatsDeckPage'))
const PrivacyPage = lazy(() => import('./app/(seo)/privacy/PrivacyContent'))
const RecruitingPage = lazy(() => import('./RecruitingPage'))

export const routes = (
  <>
    <Route
      path='/'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <Home />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='/home'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <Home />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='players-home'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <Home selectedPage='players-home' />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='leagues-home'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <Home selectedPage='leagues-home' />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='editor'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <App />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='watch'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <WatcherPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='insights'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <InsightsPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='statsrecord'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <StatRecordPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='signin'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <SignInPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='about'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <About />
          </Suspense>
        </BodyScrollingContainer>
      }
    />
    <Route
      path='media'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <MediaPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='bookareview'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <BookAReview />
          </Suspense>
        </BodyScrollingContainer>
      }
    />
    <Route
      path='deleteaccount'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <DeleteAccountPage />
          </Suspense>
        </BodyScrollingContainer>
      }
    />
    <Route
      path='internalsupport'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <InternalSupportPage />
          </Suspense>
        </BodyScrollingContainer>
      }
    />
    <Route
      path='rankings'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <GroupRankingPage />
          </Suspense>
        </BodyScrollingContainer>
      }
    />
    <Route
      path='leaderboards'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <PublicLeaderboardPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='league/statsregistration'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <TeamStatsRegistrationPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='teaminvite'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <TeamInvitePage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='admin'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <AdminPage />
          </Suspense>
        </FullPageContainer>
      }
    />
    <Route
      path='recruiting'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <RecruitingPage />
          </Suspense>
        </BodyScrollingContainer>
      }
    />
    <Route
      path='storybook'
      element={
        <Suspense fallback={<PageLoader />}>
          <StoryBook />
        </Suspense>
      }
    />
    <Route
      path='leagues'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <LeagueStatsDeckPage />
          </Suspense>
        </FullPageContainer>
      }
    />

    <Route
      path='privacy'
      element={
        <BodyScrollingContainer>
          <Suspense fallback={<PageLoader />}>
            <PrivacyPage />
          </Suspense>
        </BodyScrollingContainer>
      }
    />

    <Route
      path='*'
      element={
        <FullPageContainer>
          <Suspense fallback={<PageLoader />}>
            <Home />
          </Suspense>
        </FullPageContainer>
      }
    />
  </>
)

export default function AppRoutes() {
  return <Routes>{routes}</Routes>
}
